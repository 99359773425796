<template>
  <div class="bg-white p-3">
    <b-table
      fixed
      striped
      hover
      :fields="fields"
      :items="reportList"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <template #cell(excel_type_name)="{ item }">
        {{
          item.excel_type_name.charAt(0).toUpperCase() +
          item.excel_type_name.slice(1)
        }}
      </template>
      <template #cell(name)="{ item }">
        <div
          class="text-underline cursor-pointer"
          @click="downloadFile(item)"
          v-if="item.have_file"
        >
          {{ item.name }}
        </div>
        <div v-else class="d-flex align-items-center justify-content-center">
          <b-spinner label="Loading..." small class="mr-2"></b-spinner>Loading
          ...
        </div>
      </template>
      <template #cell(created_time)="{ item }">
        {{ $moment(item.created_time).format("DD MMM YYYY (HH:mm:ss)") }}
      </template>
      <template #cell(action)="{ item }">
        <font-awesome-icon
          icon="file-excel"
          class="cursor-pointer"
          @click="downloadFile(item)"
          v-if="item.have_file"
        /><font-awesome-icon
          icon="trash-alt"
          class="cursor-pointer ml-2 text-error"
          @click="removeFile(item.id)"
          v-if="item.have_file"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
var time = null;
export default {
  data() {
    return {
      reportList: [],
      isBusy: true,
      fields: [
        {
          key: "excel_type_name",
          label: "Page Name",
          class: "w-15",
        },
        {
          key: "display_name",
          label: "Name",
          class: "w-auto",
        },
        {
          key: "name",
          label: "File Name",
          class: "w-auto",
        },
        {
          key: "created_time",
          label: "Created Time",
          class: "w-20",
        },
        {
          key: "action",
          label: "",
          class: "w-1",
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  beforeDestroy() {
    clearInterval(time);
  },
  methods: {
    async getList() {
      this.isBusy = true;
      const res = await this.$report(`/Download/list_report_customer`);
      this.reportList = res.data.detail;
      let isWait =
        res.data.detail.filter((el) => !el.have_file).length > 0 ? true : false;
      if (isWait) {
        clearInterval(time);
        time = setInterval(() => {
          this.getList();
        }, 10000);
      }
      if (!isWait && time) clearInterval(time);
      this.isBusy = false;
    },
    async removeFile(id) {
      const res = await this.$report.delete(
        `/Download/delete_report_customer/${id}`
      );
      if (res.data.result) {
        this.getList();
      } else {
        this.errorAlert();
      }
    },
    async downloadFile({ name, id }) {
      this.$bus.$emit("showLoading");
      const result = await this.$report(`/Download/get_report_customer/${id}`, {
        responseType: "blob",
      });

      var fileURL = window.URL.createObjectURL(new Blob([result.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", name);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.getList();
      this.$bus.$emit("hideLoading");
    },
  },
};
</script>

<style></style>
